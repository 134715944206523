import React from "react"
import { RichText } from "prismic-reactjs"
import { Link } from "gatsby-plugin-intl"

import { injectIntl } from "gatsby-plugin-intl"

import "./Teaser.scss"

function TeaserServices({ intl, title, edito, services }) {
  return (
    <div className="Teaser">
      <div className="container">
        <div className="edito">
          {title && RichText.render(title.richText)}
          {edito && RichText.render(edito.richText)}
        </div>
        {services && (
          <div className="Teaser-quartet">
            <ul>
              {services.map(
                ({ node }) =>
                  node && (
                    <li key={node.uid}>
                      <span
                        className={`Teaser-quartet-icon Teaser-quartet-icon--${node.uid}`}
                      ></span>
                      <h3 className="Teaser-quartet-title">
                        {RichText.asText(node.data.name.richText)}
                      </h3>
                      {RichText.render(node.data.teaser.richText)}
                    </li>
                  )
              )}
            </ul>
          </div>
        )}
      </div>

      <div className="Teaser-button">
        <Link to="/services" className="Button ButtonPrimary">
          {intl.formatMessage({ id: "services_button" })}
        </Link>
      </div>
    </div>
  )
}

export default injectIntl(TeaserServices)
