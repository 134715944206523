import React from "react"

import "./Welcomer.scss"

function Welcomer({ title, subtitle, visual }) {
  function handleArrowClick() {
    window.scroll({
      top: window.innerHeight,
      behavior: "smooth",
    })
  }


  return (
    <div className="Welcomer">
      <div className="Welcomer-visual">
        {visual && (
          <picture>
            <source media="(min-width: 1024px)" srcSet={visual.url} />
            <source media="(min-width: 640px)" srcSet={visual?.thumbnails.tablet?.url} />
            <source srcSet={visual?.thumbnails?.mobile?.url} />
            <img src={visual.url} alt={visual.alt} />
          </picture>
        )}
      </div>
      <div className="container">
        <div className="Welcomer-side">
          <div className="Welcomer-side-text">
            {subtitle && <p>{subtitle.text}</p>}
            {title && <h1>{title.text}</h1>}
          </div>
          <div
            className="Welcomer-side-arrow"
            onClick={() => handleArrowClick()}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default Welcomer
