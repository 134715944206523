import React from "react"
import { RichText } from "prismic-reactjs"
import { Link } from "gatsby-plugin-intl"

import { injectIntl } from "gatsby-plugin-intl"

import "./Contact.scss"

function Contact({ intl, title, edito, visual, phone, mail }) {
  return (
    <div className="Contact">
      <div className="container">
        <div className="Contact-sides">
          <div className="Contact-side Contact-side--visual">
            {visual && (
              <div className="Contact-visual">
                <picture>
                  <source media="(min-width: 1024px)" srcSet={visual.url} />
                  <source media="(min-width: 640px)" srcSet={visual?.thumbnails.tablet?.url} />
                  <source srcSet={visual?.thumbnails.mobile?.url} />
                  <img src={visual.url} alt={visual.alt} />
                </picture>
              </div>
            )}
          </div>
          <div className="Contact-side Contact-side--text">
            <div className="edito">
              <span className="edito--title">
                {title && RichText.render(title.richText)}
              </span>
              {edito && RichText.render(edito.richText)}
              {phone && (
                <div className="Contact-intel Contact-intel--phone">
                  <a href={`tel:${RichText.asText(phone.richText)}`}>{RichText.asText(phone.richText)}</a>
                </div>
              )}
              {mail && (
                <div className="Contact-intel Contact-intel--mail">
                  <a href={`mailto:${RichText.asText(mail.richText)}`}>{RichText.asText(mail.richText)}</a>
                </div>
              )}
              <div className="Contact-button">
                <Link to="/contact" className="Button ButtonPrimary">
                  {intl.formatMessage({ id: "contact_button" })}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Contact)
