import React from "react"

import "./BannerVisual.scss"

function BannerVisual({ visual }) {
  return (
    <div className="BannerVisual">
      <div className="container">
        <div className="BannerVisual-image">
          {visual && (
            <picture>
              <source media="(min-width: 1024px)" srcSet={visual.url} />
              <source media="(min-width: 640px)" srcSet={visual?.thumbnails.tablet?.url} />
              <source srcSet={visual?.thumbnails.mobile?.url} />
              <img src={visual.url} alt={visual.alt} />
            </picture>
          )}
        </div>
      </div>
    </div>
  )
}

export default BannerVisual
