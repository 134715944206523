import React from "react"
import { RichText } from "prismic-reactjs"

import "./Teaser.scss"

function TeaserTrust({ title, edito, brand }) {
  
  return (
    <div className="Teaser Teaser--line">
      <div className="container">
        <div className="edito">
          {title && RichText.render(title.richText)}
          {edito && RichText.render(edito.richText)}
        </div>
        {brand.length > 0 && 
        <div className="Teaser-trust-logos">
          {brand.map(({client_logo}) => (
            <div className="Teaser-trust-logo">
              <div className="Teaser-trust-logo-img" style={{backgroundImage: `url(${client_logo.url})`}}></div>
            </div>
          ))}
        </div>
        }
      </div>
    </div>
  )
}

export default TeaserTrust
