import React from "react"
import { RichText } from "prismic-reactjs"
import { Link } from "gatsby-plugin-intl"

import { injectIntl } from "gatsby-plugin-intl"

import "./Teaser.scss"
import TeaserSlider from "@components/home/teaser/teaser-slider/TeaserSlider"

function TeaserBrand({ intl, title, edito, brand }) {
  
  return (
    <div className="Teaser Teaser--grey">
      <div className="container">
        <div className="edito">
          {title && RichText.render(title.richText)}
          {edito && RichText.render(edito.richText)}
        </div>
        {brand && <TeaserSlider brand={brand} />}
      </div>
      <div className="Teaser-button">
        <Link to="/marques" className="Button ButtonPrimary">
          {intl.formatMessage({ id: "brand_button" })}
        </Link>
      </div>
    </div>
  )
}

export default injectIntl(TeaserBrand)
