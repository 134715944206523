import React from "react"
import { graphql } from 'gatsby'
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "@layouts/Layout"
import Metatag from "@components/metatag/Metatag"
import Welcomer from "@components/home/welcomer/Welcomer"
import About from "@components/home/about/About"
import TeaserBrands from "@components/home/teaser/TeaserBrands"
import TeaserTrust from "@components/home/teaser/TeaserTrust"
import BannerVisual from "@components/common/banner-visual/BannerVisual"
import TeaserServices from "@components/home/teaser/TeaserServices"
import Contact from "@components/home/contact/Contact"

function IndexPage({ data, location }) {
  const content = data?.prismicHomepage?.data
  const services = data?.allPrismicService?.edges

  if (!content) return null

  return (
    <Layout>
      <Metatag href={location.href} origin={location.origin}/>
      <Welcomer
        subtitle={content.welcome_subtitle}
        title={content.welcome_title}
        visual={content.welcome_cover}
      />
      <About
        title={content.about_title}
        edito={content.about_edito}
        visual={content.about_visual}
      />
      <TeaserBrands
        title={content.brands_title}
        edito={content.brands_edito}
        brand={content.brands_slider}
      /> 
      <BannerVisual
        visual={content.separator_visual}
      />
      <TeaserTrust
        title={content.trust_title}
        edito={content.trust_edito}
        brand={content.trust_logo}
      /> 
     <TeaserServices
        title={content.services_title}
        edito={content.services_edito}
        services={services}
      /> 
      <Contact
        title={content.contact_title}
        edito={content.contact_edito}
        visual={content.contact_visual}
        phone={content.contact_phone}
        mail={content.contact_mail}
      /> 
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery($gatsby_lang: String) {
    prismicHomepage(lang: { eq: $gatsby_lang }) {
      data {
        welcome_title {text}
        welcome_subtitle {text}
        welcome_cover {
          alt
          url
          thumbnails {
            mobile {
              url
            }
            tablet {
              url
            }
          }
        }
        about_title {richText}
        about_edito {richText}
        about_visual {
          alt
          url
          thumbnails {
            mobile {
              url
            }
            tablet {
              url
            }
          }
        }
        brands_title {richText}
        brands_edito {richText}
        brands_slider {
          brands_slide {
            id
            document {
              ... on PrismicBrand {
                href
                data {
                  logo {
                    alt
                    url
                  }
                }
              }
            }
          }
        }
        separator_visual {
          alt
          url
          thumbnails {
            mobile {
              url
            }
            tablet {
              url
            }
          }
        }
        trust_title {richText}
        trust_edito {richText}
        trust_edito {richText}
        services_title {richText}
        services_edito {richText}
        trust_logo {
          client_logo {
            alt
            url
          }
        }
        contact_title {richText}
        contact_edito {richText}
        contact_visual {
          url
          alt
          thumbnails {
            mobile {
              url
            }
            tablet {
              url
            }
          }
        }
        contact_phone {richText}
        contact_mail {richText}
      }
    }
    allPrismicService(filter: {lang: {eq: $gatsby_lang}}) {
      edges {
        node {
          uid
          id
          data {
            name {
              richText
            }
            teaser {
              richText
            }
          }
        }
      }
    }
  }
`

export default injectIntl(IndexPage)
