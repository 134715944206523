import React from "react"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "./TeaserSlider.scss"

function TeaserSlider({ brand }) {
  const settings = {
    className: "TeaserSlider-slides",
    infinite: false,
    animation: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <div className="TeaserSlider">
      <div className="TeaserSlider-mask">
        <div className="TeaserSlider-mask-full"></div>
        <div className="TeaserSlider-mask-gradient"></div>
      </div>
      <Slider {...settings}>
        {brand.map(
          ({ brands_slide }) =>
            brands_slide && (
              <div className="TeaserSlider-slide" key={brands_slide.document.data.logo.alt}>
                <div className="TeaserSlider-slide-logo">
                  <div className="TeaserSlider-slide-logo-img" style={{backgroundImage: `url(${brands_slide.document.data.logo?.url})`}}></div>
                </div>
              </div>
            )
        )}
      </Slider>
    </div>
  )
}

export default TeaserSlider
